export const APP_ORIGIN = 'app://kaios-plus.kaiostech.com';

export const PATH = {
  LOADING: {
    MATCH: '/loading',
    URL: () => {
      return '/loading';
    },
  },
  APPS: {
    MATCH: '/',
    URL: () => {
      return '/';
    },
  },
  SEARCH: {
    MATCH: '/search',
    URL: () => {
      return '/search';
    },
  },
  SETTING: {
    MATCH: '/setting',
    URL: () => {
      return '/setting';
    },
  },
  PAGE: {
    MATCH: '/app/:id?',
    URL: ({
      id = '',
      adId = null,
      appOrder = null,
      autoDownload = false,
      disposition = 'window',
      manifest = null,
      batchUpdating = false,
      entry = 'apps',
    }) => {
      const params = new URLSearchParams();
      const timestamp = new Date().getTime();

      params.append('t', timestamp);
      adId && params.append('adId', adId);
      typeof appOrder === 'number' && params.append('appOrder', appOrder);
      autoDownload && params.append('autoDownload', autoDownload);
      disposition && params.append('disposition', disposition);
      manifest && params.append('manifest', encodeURIComponent(manifest));
      batchUpdating && params.append('batchUpdating', batchUpdating);
      entry && params.append('entry', entry);

      return `/app/${id}?${params.toString()}`;
    },
  },
};

export const SUPPORTED_APP_TYPE = [
  'bookmark',
  'privileged',
  'hosted',
  'certified',
  'web',
];

// Google Assistant ='OSlAbgrhLArfT7grf4_N'
export const PRELOADED_APP = ['OSlAbgrhLArfT7grf4_N'];

export const ASYNC_STORAGE_KEYS = {
  USER_BROWSED_HISTORY: 'userBrowsedHistory',
};

export const KAIOS_HOMEPAGE = 'https://www.kaiostech.com/';

// special category code
export const SPECIAL_CATE_CODE = {
  ALL: 'all-apps',
  RECOMMENDED: 'recommended',
};
